import React from "react";
import { Link } from "gatsby";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";

const Component = ({ meta, base }) => {
    const pagination = meta.pagination;

    if (!pagination || pagination.pageCount <= 1 || !base) {
        return null;
    }

    const prevPage = pagination.page > 1 ? `${base}/page/${pagination.page - 1}` : null;

    const nextPage =
        pagination.page < pagination.pageCount ? `${base}/page/${pagination.page + 1}` : null;

    return (
        <section>
            <div className={styles.container}>
                <Button title={"Anterior"} link={prevPage} Icon={RiArrowDropLeftLine} />

                <Button
                    title={"Siguiente"}
                    link={nextPage}
                    Icon={RiArrowDropRightLine}
                    reverse={true}
                />
            </div>
        </section>
    );
};

const Button = ({ title, link, Icon, reverse }) => {
    const ButtonContent = (
        <>
            <Icon className={styles.buttonIcon} />
            <span className={styles.buttonText}>{title}</span>
        </>
    );

    return link ? (
        <Link to={link} className={styles.button(reverse)}>
            {ButtonContent}
        </Link>
    ) : (
        <div className={styles.button(reverse, true)}>{ButtonContent}</div>
    );
};

const styles = {
    container: "flex flex-row items-center justify-center md:justify-end gap-5",
    button: (reverse, disabled) =>
        `flex items-center space-x-3 rounded-md px-3 py-2 duration-300 hover:bg-gray-200 text-gray-700 ${
            disabled ? "cursor-not-allowed opacity-50" : ""
        } ${reverse ? "flex-row-reverse space-x-reverse" : ""}`,
    buttonText: "text-sm font-semibold uppercase",
    buttonIcon: "w-6 h-6",
};

export default Component;
